import React from "react";

export class Textarea extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }


    handleChange(e) {
        this.setState({
            value: e.target.value,
        })
    }

    handleBlur() {
        this.props.editWords(this.state.value, this.props.language, this.props.index)
    }

    render() {
        return(
            <textarea value={this.state.value} onChange={this.handleChange} onBlur={this.handleBlur}/>
        )
    }
}
