import React from "react";
import home from "../Image/home.svg";
export class CollectionJobs extends React.Component{
    render() {
        return(
            <div className="collectionJobs">
                <p>Properties collection</p>
                <div className="selectJobs">
                    <input type="text" placeholder="Type property value..."/>
                    <select name="chooseState" id="chooseState">
                        <option value="English">English</option>
                        <option value="Русский">Русский</option>
                    </select>
                    <select name="chooseArea" id="chooseArea">
                        <option value="English">English</option>
                        <option value="Русский">Русский</option>
                    </select>
                    <select name="chooseCity" id="chooseCity">
                        <option value="English">English</option>
                        <option value="Русский">Русский</option>
                    </select>
                    <select name="chooseType" id="chooseType">
                        <option value="English">English</option>
                        <option value="Русский">Русский</option>
                    </select>
                </div>
                <div className="table">
                    <table>
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Name</th>
                                <th>Owner</th>
                                <th>Area</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Created</th>
                                <th>Subscribers</th>
                                <th>Jobs</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><img src={home} alt=""/></td>
                                <td>My house</td>
                                <td>Hadar Paz</td>
                                <td>Beverly Hill</td>
                                <td>Los Angeles</td>
                                <td>CA</td>
                                <td>05/23/20</td>
                                <td>5</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td><img src={home} alt=""/></td>
                                <td>My house</td>
                                <td>Hadar Paz</td>
                                <td>Beverly Hill</td>
                                <td>Los Angeles</td>
                                <td>CA</td>
                                <td>05/23/20</td>
                                <td>5</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td><img src={home} alt=""/></td>
                                <td>My house</td>
                                <td>Hadar Paz</td>
                                <td>Beverly Hill</td>
                                <td>Los Angeles</td>
                                <td>CA</td>
                                <td>05/23/20</td>
                                <td>5</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td><img src={home} alt=""/></td>
                                <td>My house</td>
                                <td>Hadar Paz</td>
                                <td>Beverly Hill</td>
                                <td>Los Angeles</td>
                                <td>CA</td>
                                <td>05/23/20</td>
                                <td>5</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td><img src={home} alt=""/></td>
                                <td>My house</td>
                                <td>Hadar Paz</td>
                                <td>Beverly Hill</td>
                                <td>Los Angeles</td>
                                <td>CA</td>
                                <td>05/23/20</td>
                                <td>5</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td><img src={home} alt=""/></td>
                                <td>My house</td>
                                <td>Hadar Paz</td>
                                <td>Beverly Hill</td>
                                <td>Los Angeles</td>
                                <td>CA</td>
                                <td>05/23/20</td>
                                <td>5</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td><img src={home} alt=""/></td>
                                <td>My house</td>
                                <td>Hadar Paz</td>
                                <td>Beverly Hill</td>
                                <td>Los Angeles</td>
                                <td>CA</td>
                                <td>05/23/20</td>
                                <td>5</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td><img src={home} alt=""/></td>
                                <td>My house</td>
                                <td>Hadar Paz</td>
                                <td>Beverly Hill</td>
                                <td>Los Angeles</td>
                                <td>CA</td>
                                <td>05/23/20</td>
                                <td>5</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td><img src={home} alt=""/></td>
                                <td>My house</td>
                                <td>Hadar Paz</td>
                                <td>Beverly Hill</td>
                                <td>Los Angeles</td>
                                <td>CA</td>
                                <td>05/23/20</td>
                                <td>5</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td><img src={home} alt=""/></td>
                                <td>My house</td>
                                <td>Hadar Paz</td>
                                <td>Beverly Hill</td>
                                <td>Los Angeles</td>
                                <td>CA</td>
                                <td>05/23/20</td>
                                <td>5</td>
                                <td>3</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="nextPage">
                        <p>Page 1 of 136 ></p>
                    </div>
                </div>
            </div>
        )
    }
}