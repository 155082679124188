import React from "react";
import {CollectionTranslation} from "./CollectionTranslation";
import {database} from "../index";

export class Translation extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            firstQuantity: {},
            secondQuantity: {},
        }

        this.getQuantity = this.getQuantity.bind(this);
    }

    getQuantity(first, second) {
        if (first && second) {
            this.setState({
                firstQuantity: first,
                secondQuantity: second,
            })
        }
    }

    render() {
        console.log(this.state.firstQuantity.wordsCount)
        return(
            <React.Fragment>
                <div className="path">
                    <p>Languages > Translation</p>
                </div>
                <div className="head" style={{justifyContent: "flex-start"}}>
                    <aside className="aside" style={{marginRight: "30px"}}>
                        <h3>
                            {/*{this.state.firstQuantity.wordsCount}*/}
                            {(this.state.firstQuantity.wordsCount !== undefined) ? this.state.firstQuantity.wordsCount : 0}
                        </h3>
                        <p>Number of Words <br/> (first table)</p>
                    </aside>
                    <aside className="aside">
                        <h3>
                            {/*{this.state.secondQuantity.wordsCount}*/}
                            {(this.state.secondQuantity.wordsCount !== undefined) ? this.state.secondQuantity.wordsCount : 0}
                        </h3>
                        <p>Number of Words <br/> (second table)</p>
                    </aside>
                </div>
                <CollectionTranslation getQuantity={this.getQuantity}/>
            </React.Fragment>
        )
    }
}
