import React from "react";
import {FeedbackList} from "./FeedbackList";

export class Feedback extends React.Component{
    render() {
        return(
            <React.Fragment>
                <div className="path">
                    <p>Analytics > Feedback {`> ${this.props.country}`}</p>
                </div>
                <div className="head" style={{justifyContent: "flex-start"}}>
                    <aside className="aside" style={{marginRight: "30px"}}>
                        <h3>126</h3>
                        <p>Number of Feedbacks</p>
                    </aside>
                    <aside className="aside">
                        <h3>4,76</h3>
                        <p>Average rating</p>
                    </aside>
                </div>
                <FeedbackList/>
            </React.Fragment>
        )
    }
}