import React from "react";
import {database} from "../index";
import {Textarea} from "./Textarea";

export class CollectionTranslation extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            quantity: [],
            firstLanguage: "",
            secondLanguage: "",
            languages: [],
            words: [],
        }

        this.editWords = this.editWords.bind(this);
        this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
        this.getWordsCount = this.getWordsCount.bind(this);
    }



    componentDidMount() {
        database.ref(`Languages`).on('value', snapshot => {
            const data = snapshot.val();

            const quantity = [];
            const words = [];

            const languages = Object.values(data.AvailableLanguages);
            const list = Object.entries(data.LanguageValues);

            languages.forEach((firstItem, firstIndex) => {
                if (firstItem.Enabled) {
                    if (firstItem.Code === "en") {
                        this.setState({
                            firstLanguage: firstItem.Code,
                        })
                    }

                    if (firstItem.Code === "ru") {
                        this.setState({
                            secondLanguage: firstItem.Code,
                        })
                    }

                    quantity.push({
                        language: firstItem.Code,
                        wordsCount: 0,
                    })

                    list.forEach((secondItem) => {
                        if (secondItem[0] === firstItem.Code) {
                            Object.entries(secondItem[1]).forEach((thirdItem) => {
                                quantity[firstIndex].wordsCount += 1;
                                const index = words.findIndex((item, index) => item.key === thirdItem[0] );
                                if (index === -1) {
                                    words.push({
                                        key: thirdItem[0],
                                        [`text_${secondItem[0]}`]: thirdItem[1].Text,
                                    })
                                } else {
                                    words[index][`text_${secondItem[0]}`] = thirdItem[1].Text;
                                }
                            })
                        }
                    })
                }
            })
            // const first = quantity.find((item) => {
            //     if (item.language === this.state.firstLanguage) {
            //         return item.wordsCount;
            //     }
            // })
            //
            // const second = quantity.find((item) => {
            //     if (item.language === this.state.secondLanguage) {
            //         return item.wordsCount;
            //     }
            // })
            // this.props.getQuantity(first, second)
            this.getWordsCount(quantity)

            this.setState({
                quantity: quantity,
                languages: languages,
                words: words,
            })
        });
        this.getWordsCount(this.state.quantity)
    }

    getWordsCount(quantity) {
        const first = quantity.find((item) => {
            if (item.language === this.state.firstLanguage) {
                return item.wordsCount;
            }
        })

        const second = quantity.find((item) => {
            if (item.language === this.state.secondLanguage) {
                return item.wordsCount;
            }
        })

        this.props.getQuantity(first, second)
    }

    async handleChangeLanguage(e) {
        const {value} = e.target;
        if (e.target.id === "chooseLangFirst") {
            await this.setState((prevState) => ({
                ...prevState,
                firstLanguage: value,
                secondLanguage: (this.state.secondLanguage === value) ? this.state.firstLanguage : this.state.secondLanguage,
            }))
            await this.getWordsCount(this.state.quantity);
        } else if (e.target.id === "chooseLangNext") {
            await this.setState((prevState) => ({
                ...prevState,
                firstLanguage: (this.state.firstLanguage === value) ? this.state.secondLanguage : this.state.firstLanguage,
                secondLanguage: value,
            }))
            await this.getWordsCount(this.state.quantity);
        }
    }

    editWords(value, language, index) {

        const words = [...this.state.words];

        words[index][`text_${language}`] = value;

        this.setState({
            words: words,
        })

        this.getWordsCount(this.state.quantity);

        // this.props.getQuantity(this.state.firstQuantity, this.state.secondQuantity);
    }

    render() {
        let i = 0;

        const language = this.state.languages.map((item) => {
            return (
                <option key={`${item.Name}_first`} value={item.Code} >{item.Name}</option>
            )
        })

        const data = this.state.words.map((item, index) => {
            return (
                <tr key={`${item.key}_${i}`}>
                    <td key={i}>{i++}</td>
                    <td key={`${item.key}_${i}`} className="key">{item.key}</td>
                    <td key={item[`text_${this.state.firstLanguage}`]}>
                        <Textarea value={item[`text_${this.state.firstLanguage}`]} index={index} language={this.state.firstLanguage} editWords={this.editWords}/>
                    </td>
                    <td key={item[`text_${this.state.secondLanguage}`]}>
                        <Textarea value={item[`text_${this.state.secondLanguage}`]} index={index} language={this.state.secondLanguage} editWords={this.editWords}/>
                    </td>
                </tr>
            )
        })

        return(
            <div className="collectionTranslation">
                <p>Words Collection</p>
                <div className="selectLanguage" >
                    <select name="chooseLangFirst" id="chooseLangFirst" value={this.state.firstLanguage ? this.state.firstLanguage : ""} onChange={this.handleChangeLanguage}>
                        {/*<option value="en">English</option>*/}
                        {/*<option value="ru">Русский</option>*/}
                        {language}
                    </select>
                    <p> > </p>
                    <select name="chooseLangNext" id="chooseLangNext" value={this.state.secondLanguage ? this.state.secondLanguage : ""} onChange={this.handleChangeLanguage}>
                        {/*<option value="ru">Русский</option>*/}
                        {/*<option value="en">English</option>*/}
                        {language}
                    </select>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>Key</th>
                            <th>Value</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data}
                        {/*<tr>*/}
                        {/*    <td>1</td>*/}
                        {/*    <td>SCHEDULE_NOW-STRING</td>*/}
                        {/*    <td>"SCHEDULE"</td>*/}
                        {/*    <td>"ПЛАН"</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>1</td>*/}
                        {/*    <td>SCHEDULE_NOW-STRING</td>*/}
                        {/*    <td>"SCHEDULE"</td>*/}
                        {/*    <td>"ПЛАН"</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>1</td>*/}
                        {/*    <td>SCHEDULE_NOW-STRING</td>*/}
                        {/*    <td>"SCHEDULE"</td>*/}
                        {/*    <td>"ПЛАН"</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>1</td>*/}
                        {/*    <td>SCHEDULE_NOW-STRING</td>*/}
                        {/*    <td>"SCHEDULE"</td>*/}
                        {/*    <td>"ПЛАН"</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>1</td>*/}
                        {/*    <td>SCHEDULE_NOW-STRING</td>*/}
                        {/*    <td>"SCHEDULE"</td>*/}
                        {/*    <td>"ПЛАН"</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>1</td>*/}
                        {/*    <td>SCHEDULE_NOW-STRING</td>*/}
                        {/*    <td>"SCHEDULE"</td>*/}
                        {/*    <td>"ПЛАН"</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>1</td>*/}
                        {/*    <td>SCHEDULE_NOW-STRING</td>*/}
                        {/*    <td>"SCHEDULE"</td>*/}
                        {/*    <td>"ПЛАН"</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>1</td>*/}
                        {/*    <td>SCHEDULE_NOW-STRING</td>*/}
                        {/*    <td>"SCHEDULE"</td>*/}
                        {/*    <td>"ПЛАН"</td>*/}
                        {/*</tr>*/}
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}
