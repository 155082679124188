import React from 'react';
import ReactDOM from 'react-dom';
import {App} from './App';
import * as serviceWorker from './serviceWorker';
import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyCmouoPiVlExeOiaSc7YBlq5JMVy_S9dwY",
    authDomain: "littlejobsdev.firebaseapp.com",
    databaseURL: "https://littlejobsdev.firebaseio.com",
    projectId: "littlejobsdev",
    storageBucket: "littlejobsdev.appspot.com",
    messagingSenderId: "407345892054",
    appId: "1:407345892054:web:7388992336e73069351777",
    measurementId: "G-CSW0DVC0KH"
};
firebase.initializeApp(firebaseConfig);

// const functions = firebase.functions();
export const database = firebase.database();

firebase.auth().onAuthStateChanged(function(user) {
    // if (!user) {
    //     console.log("USER LOGGED OUT");
    //     if (!window.location.href.startsWith("http://pazzy.co/main.html")) {
    //         document.location.href = "main.html";
    //     }
    // }
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
