import React from "react";
import profileImage from "../Image/ProfileImage.svg";

export class FeedbackList extends React.Component{
    render() {
        return(
            <div className="feedbackList">
                <div className="newFeedback">
                    <p>New Feedback</p>
                    <div>
                        <select name="chooseState" id="chooseState">
                            <option value="English">English</option>
                            <option value="Русский">Русский</option>
                        </select>
                        <select name="chooseArea" id="chooseArea">
                            <option value="English">English</option>
                            <option value="Русский">Русский</option>
                        </select>
                        <select name="chooseCity" id="chooseCity">
                            <option value="English">English</option>
                            <option value="Русский">Русский</option>
                        </select>
                        <select name="showLatestFirst" id="showLatestFirst">
                            <option value="English">English</option>
                            <option value="Русский">Русский</option>
                        </select>
                    </div>
                </div>
                <div className="feedback">
                    <img src={profileImage} alt=""/>
                    <div className="user">
                        <div className="name">
                            <p>Ron Swanson</p>
                            <p><span>IN</span> <span>Pawnee</span></p>
                        </div>
                        <div className="text">
                            <p>I have no reasons to use smartphones except using this App.</p>
                        </div>
                    </div>
                </div>
                <div className="feedback">
                    <img src={profileImage} alt=""/>
                    <div className="user">
                        <div className="name">
                            <p>Ron Swanson</p>
                            <p><span>IN</span> <span>Pawnee</span></p>
                        </div>
                        <div className="text">
                            <p>I have no reasons to use smartphones except using this App.</p>
                        </div>
                    </div>
                </div>
                <div className="feedback">
                    <img src={profileImage} alt=""/>
                    <div className="user">
                        <div className="name">
                            <p>Ron Swanson</p>
                            <p><span>IN</span> <span>Pawnee</span></p>
                        </div>
                        <div className="text">
                            <p>I have no reasons to use smartphones except using this App.</p>
                        </div>
                    </div>
                </div>
                <div className="feedback">
                    <img src={profileImage} alt=""/>
                    <div className="user">
                        <div className="name">
                            <p>Ron Swanson</p>
                            <p><span>IN</span> <span>Pawnee</span></p>
                        </div>
                        <div className="text">
                            <p>I have no reasons to use smartphones except using this App.</p>
                        </div>
                    </div>
                </div>
                <div className="feedback">
                    <img src={profileImage} alt=""/>
                    <div className="user">
                        <div className="name">
                            <p>Ron Swanson</p>
                            <p><span>IN</span> <span>Pawnee</span></p>
                        </div>
                        <div className="text">
                            <p>I have no reasons to use smartphones except using this App.</p>
                        </div>
                    </div>
                </div>
                <div className="nextPage">
                    <p>Page 1 of 136 </p>
                </div>
            </div>

        )
    }
}