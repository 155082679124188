import React from "react";
import logo from "../Image/im_logo.svg";
import {addWord, feedback, job, profile, property, translation} from "../Image/img.js";
import {Cookies} from "../cookies";
import * as firebase from "firebase";


export class LeftBar extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            selectPage: "Properties",
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleClickExit = this.handleClickExit.bind(this);
    }

    handleClick(e) {
        // console.log(e.target.dataset.id);
        // this.setState({
        //     selectPage: e.target.dataset.id,
        // })


        this.props.selectPage(e.target.dataset.id);
        this.select(e.target.dataset.id);

        e.stopPropagation();
    }
    handleClickExit() {
        Cookies.deleteAllCookies();
        firebase.auth().signOut();
        this.props.logIn(false);
    }

    select(list) {
        if (list) {
            const page = document.querySelector(`#${list}`);
            const deletePage = document.querySelector(".active");

            deletePage.classList.remove("active");
            page.classList.add("active");
        }
    }

    render() {

        return(
            <header className="leftBar">
                <div className="logo">
                    <img src={logo} alt="" onClick={this.handleClickExit}/>
                </div>
                <nav className="menu">
                    <h4>Analytics</h4>
                    <ul onClick={this.handleClick}>
                        <li data-id="properties" id="properties" className="active">
                            {property}
                            <p data-id="properties">Properties</p>
                        </li>
                        <li data-id="jobs" id="jobs">
                            {job}
                            <p data-id="jobs">Jobs</p>
                        </li>
                        <li data-id="profiles" id="profiles">
                            {profile}
                            <p data-id="profiles">Profiles</p>
                        </li>
                        <li data-id="feedback" id="feedback">
                            {feedback}
                            <p data-id="feedback">Feedback</p>
                        </li>
                    </ul>
                </nav>
                <nav className="menu">
                    <h4>Languages</h4>
                    <ul onClick={this.handleClick}>
                        <li data-id="translation" id="translation">
                            {translation}
                            <p data-id="translation">Translation</p>
                        </li>
                        <li data-id="addWord" id="addWord">
                            {addWord}
                            <p data-id="addWord">Add Word</p>
                        </li>
                    </ul>
                </nav>
            </header>
        )
    }
}
