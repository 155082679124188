import React from 'react';
import {LeftBar} from "./LeftBar/LeftBar";
import {Article} from "./Article/Article";
import {RightBar} from "./RightBar/RightBar";
import {database} from "./index";
import {StartPage} from "./StartPage";
import {Cookies} from "./cookies";

export class App extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            // login: P,
            login: Cookies.getCookie("login"),
            selectPage: "",
            selectCountry: "USA",
            countries: {},
            feedback: {},
            languages: {},
            profiles: {},
            properties: {},
            propertyTypes: {},
            skills: {}
        }

        this.selectPage = this.selectPage.bind(this);
        this.selectCountry = this.selectCountry.bind(this);
    }

    componentDidMount() {
    //     database.ref(`Countries`).on('value', snapshot => {
    //         const countries = snapshot.val();
    //         this.setState({
    //             countries: countries,
    //         })
    //     });
    //     database.ref(`Feedback`).on('value', snapshot => {
    //         const feedback = snapshot.val();
    //         this.setState({
    //             feedback: feedback,
    //         })
    //     });
    //     database.ref(`Languages`).on('value', snapshot => {
    //         const languages = snapshot.val();
    //         this.setState({
    //             languages: languages,
    //         })
    //     });
    //     database.ref(`Profiles`).on('value', snapshot => {
    //         const profiles = snapshot.val();
    //         this.setState({
    //             profiles: profiles,
    //         })
    //     });
    //     database.ref(`Properties`).on('value', snapshot => {
    //         const properties = snapshot.val();
    //         this.setState({
    //             properties: properties,
    //         })
    //     });
    //     database.ref(`PropertyTypes`).on('value', snapshot => {
    //         const propertyTypes = snapshot.val();
    //         this.setState({
    //             propertyTypes: propertyTypes,
    //         })
    //     });
    //     database.ref(`Skills`).on('value', snapshot => {
    //         const skills = snapshot.val();
    //         this.setState({
    //             skills: skills,
    //         })
    //     });
    //
    }

    logIn = (login) => {
        this.setState({
            login: login,
        })
    }

    selectPage(page) {
        this.setState({
            selectPage: page,
        })
    }

    selectCountry(country) {
        this.setState({
            selectCountry: country,
        })
    }

    render() {
        if (this.state.login) {
            return (
                <main className="main">
                    <LeftBar selectPage={this.selectPage} logIn={this.logIn}/>
                    <Article selectPage={this.state.selectPage} selectCountry={this.state.selectCountry}/>
                    <RightBar selectPage={this.state.selectPage} selectCountry={this.selectCountry}/>
                </main>
            )
        } else {
            return(
                <StartPage logIn={this.logIn}/>
                // <main className="main">
                //     <LeftBar selectPage={this.selectPage}/>
                //     <Article selectPage={this.state.selectPage} selectCountry={this.state.selectCountry}/>
                //     <RightBar selectPage={this.state.selectPage} selectCountry={this.selectCountry}/>
                // </main>
            )
        }
        // return(
        //     <StartPage/>
        //     // <main className="main">
        //     //     <LeftBar selectPage={this.selectPage}/>
        //     //     <Article selectPage={this.state.selectPage} selectCountry={this.state.selectCountry}/>
        //     //     <RightBar selectPage={this.state.selectPage} selectCountry={this.selectCountry}/>
        //     // </main>
        // )
    }
}


