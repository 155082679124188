import React from "react";
import {AddWord} from "./AddWord";
import {Translation} from "./Translation";
import {Properties} from "./Properties";
import {Feedback} from "./Feedback";
import {Jobs} from "./Jobs";
import {Profiles} from "./Profiles";

export class Article extends React.Component{
    constructor(props) {
        super(props);

        this.switch = this.switch.bind(this);
    }


    switch(page) {
        switch (page) {
            case 'properties':
                return <Properties country={this.props.selectCountry}/>;
            case 'jobs':
                return <Jobs country={this.props.selectCountry}/>;
            case 'profiles':
                return <Profiles country={this.props.selectCountry}/>;
            case 'feedback':
                return <Feedback country={this.props.selectCountry}/>;
            case 'translation':
                return <Translation/>;
            case 'addWord':
                return <AddWord/>;
            default:
                return <Properties/>;
        }
    }

    render() {
        return(
            <article className="article">
                <section className="section">
                    {this.switch(this.props.selectPage)}
                </section>
            </article>
        )
    }
}
