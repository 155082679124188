import React from "react";
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts';

const data = [
    {name: 'Page A', uv: 4000, pv: 2400, amt: 2400,},
    {name: 'Page B', uv: 3000, pv: 1398, amt: 2210,},
    {name: 'Page C', uv: 2000, pv: 9800, amt: 2290,},
    {name: 'Page D', uv: 2780, pv: 3908, amt: 2000,},
    {name: 'Page E', uv: 1890, pv: 4800, amt: 2181,},
    {name: 'Page F', uv: 2390, pv: 3800, amt: 2500,},
    // {name: 'Page G', uv: 3490, pv: 4300, amt: 2100,},
];

export class Chart extends React.Component{
    render() {
        return(
            <div className="chart">
                <div className="chartLegend">
                    <p>New Properties</p>
                    <select name="setMounts" id="setMounts">
                        <option value="6">Last 6 mounts</option>
                        <option value="12">Last 12 mounts</option>
                    </select>
                </div>
                <div className="chartInfo">
                    <LineChart
                        width={700}
                        height={300}
                        data={data}
                        margin={{
                            top: 5, right: 30, left: 20, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="0 0" stroke="#fff"/>
                        <XAxis dataKey="name" stroke="#fff"/>
                        <YAxis stroke="#fff"/>
                        <Tooltip />
                        <Line type="monotone" dataKey="pv" stroke="#A3A0FB"  />
                        {/*<Line type="monotone" dataKey="uv" stroke="#82ca9d" />*/}
                    </LineChart>
                </div>
            </div>
        )
    }
}