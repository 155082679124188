import React from "react";

export class AddWord extends React.Component{
    render() {
        return(
            <React.Fragment>
                <div className="path">
                    <p>Languages > Add Word</p>
                </div>
                <div className="head">
                    <aside className="aside">
                        <h3>450</h3>
                        <p>Number of Words</p>
                    </aside>
                </div>
                <section className="addWord">
                    <p>Add Word</p>
                    <form>
                        <select name="chooseLanguage" id="chooseLanguage">
                            <option value="English">English</option>
                            <option value="Русский">Русский</option>
                        </select>
                        <input type="text" placeholder="Type word key..."/>
                        <input type="text" placeholder="Type word value..."/>
                        <button>Add Word</button>
                    </form>
                </section>
            </React.Fragment>
        )
    }
}