import React from "react";
import {Chart} from "./Chart";
import {CollectionJobs} from "./CollectionJobs";

export class Properties extends React.Component{
    render() {
        return(
            <React.Fragment>
                <div className="path">
                    <p>Analytics > Properties {`> ${this.props.country}`}</p>
                </div>
                <div className="head">
                    <aside className="aside" style={{marginRight: "30px"}}>
                        <h3>1355</h3>
                        <p>Number of Properties</p>
                    </aside>
                    <aside className="aside" style={{marginRight: "30px"}}>
                        <h3>9</h3>
                        <p>Average number of Jobs</p>
                    </aside>
                    <aside className="aside">
                        <h3>4</h3>
                        <p>Average number of Subscribers</p>
                    </aside>
                </div>
                <Chart/>
                <CollectionJobs/>
            </React.Fragment>
        )
    }
}