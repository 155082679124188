import React from "react";
import hero from "../Image/im_welcome_hero.svg"

export class RightBar extends React.Component{
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.props.selectCountry(e.target.value);
    }

    render() {
        let chooseCountry;

        if (this.props.selectPage !== "translation" && this.props.selectPage !== "addWord") {
            chooseCountry = {display: "flex"};
        }
        return(
            <div className="rightBar">
                <div className="chooseCountry" style={chooseCountry}>
                    <p>Choose country</p>
                    <select name="chooseCountry" id="chooseCountry" onChange={this.handleChange}>
                        <option value="USA">USA</option>
                        <option value="Russia">Russia</option>
                    </select>
                </div>
                <div className="hero">
                    <img src={hero} alt=""/>
                </div>
            </div>
        )
    }
}