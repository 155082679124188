import React from "react";
import logo from "./Image/im_logo.svg";
import screenshot from "./Image/screenshot.png";
import {Login} from "./Login";

export class StartPage extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            login: false
        }

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState({
            login: !this.state.login,
        })
    }

    render() {
        return(
            <div className="startPage">
                <header className="startPageHeader">
                    <img src={logo} alt=""/>
                    <button onClick={this.handleClick}>SIGN IN</button>
                </header>
                <main className="startPageMain">
                    <img src={screenshot} alt="" width="300px" height="500px"/>
                    <article className="startPageArticle">

                    </article>
                </main>
                {(this.state.login) ? <Login logIn={this.props.logIn}/> : null}
            </div>
        )
    }
}
