import React from "react";

export const property = (
    <svg data-id="properties" id="ic_property" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path data-id="properties" id="baseline-home-24px" d="M10.8,21.7V15.1h4.4v6.6h5.5V12.9H24L13,3,2,12.9H5.3v8.8Z" transform="translate(-1)" />
        <rect data-id="properties" id="background_properites" width="24" height="24" fill="none"/>
    </svg>
)

export const job = (
    <svg data-id="jobs" id="ic_job" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g data-id="jobs" id="work" transform="translate(1.083 3.235)">
            <path data-id="jobs" id="Контур_12" data-name="Контур 12" d="M14.417,6.125a.917.917,0,0,1-.917-.917V3.833H9.833V5.208A.917.917,0,0,1,8,5.208V3.833A1.835,1.835,0,0,1,9.833,2H13.5a1.835,1.835,0,0,1,1.833,1.833V5.208A.917.917,0,0,1,14.417,6.125Z" transform="translate(-0.667 -2)" />
            <path data-id="jobs" id="Контур_13" data-name="Контур 13" d="M11.651,15.067a1.888,1.888,0,0,1-.651.11,2.009,2.009,0,0,1-.706-.128L0,11.62v6.994a2.519,2.519,0,0,0,2.521,2.521H19.479A2.519,2.519,0,0,0,22,18.614V11.62Z" transform="translate(0 -2.802)" />
            <path data-id="jobs" id="Контур_14" data-name="Контур 14" d="M22,7.521v2.1L11.22,13.213a.678.678,0,0,1-.44,0L0,9.62v-2.1A2.519,2.519,0,0,1,2.521,5H19.479A2.519,2.519,0,0,1,22,7.521Z" transform="translate(0 -2.25)" />
        </g>
        <rect data-id="jobs" id="background_jobs" width="24" height="24" fill="none"/>
    </svg>
)

export const profile = (
    <svg data-id="profiles" id="ic_profile" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <rect data-id="profiles" id="background_profile" width="24" height="24" fill="none"/>
        <path data-id="profiles" id="baseline-assignment_ind-24px" d="M20.6,3.2H16a3.287,3.287,0,0,0-6.2,0H5.2A2.206,2.206,0,0,0,3,5.4V20.8A2.206,2.206,0,0,0,5.2,23H20.6a2.206,2.206,0,0,0,2.2-2.2V5.4A2.206,2.206,0,0,0,20.6,3.2Zm-7.7,0a1.1,1.1,0,1,1-1.1,1.1A1.1,1.1,0,0,1,12.9,3.2Zm0,4.4a3.3,3.3,0,1,1-3.3,3.3A3.3,3.3,0,0,1,12.9,7.6Zm6.6,13.2H6.3V19.26c0-2.2,4.4-3.41,6.6-3.41s6.6,1.21,6.6,3.41Z" transform="translate(-1)" />
    </svg>
)

export const feedback = (
    <svg data-id="feedback" id="ic_feedback" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g data-id="feedback" id="Speech_Bubble_48_" transform="translate(1 2)">
            <g data-id="feedback" id="Сгруппировать_2" data-name="Сгруппировать 2">
                <path data-id="feedback" id="Контур_11" data-name="Контур 11" d="M18.8,49.918h1.559l-1.1-1.1a3.73,3.73,0,0,1-1.065-2.206A7.862,7.862,0,0,0,22,39.984c0-4.68-4.3-9.436-11.037-9.436C3.827,30.548,0,34.924,0,39.574c0,4.68,3.867,9.053,10.963,9.053a14.87,14.87,0,0,0,3.7-.47A5.777,5.777,0,0,0,18.8,49.918Z" transform="translate(0 -30.548)" />
            </g>
        </g>
        <rect id="background_feedback" width="24" height="24" fill="none"/>
    </svg>
)

export const translation = (
    <svg data-id="translation" id="ic_translate" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <rect data-id="translation" id="background_jobs" width="24" height="24" fill="none"/>
        <g data-id="translation" id="translate" transform="translate(0.991 0.991)">
            <g data-id="translation" id="Сгруппировать_7" data-name="Сгруппировать 7" transform="translate(5.169 5.886)">
                <g data-id="translation" id="Сгруппировать_6" data-name="Сгруппировать 6">
                    <path data-id="translation" id="Контур_27" data-name="Контур 27" d="M121.085,137h-.276l-.516,2.579H121.6Z" transform="translate(-120.293 -137)" />
                </g>
            </g>
            <g data-id="translation" id="Сгруппировать_9" data-name="Сгруппировать 9" transform="translate(16.386 11.043)">
                <g data-id="translation" id="Сгруппировать_8" data-name="Сгруппировать 8">
                    <path data-id="translation" id="Контур_28" data-name="Контур 28" d="M381.374,257a6.739,6.739,0,0,0,1.059,1.868,6.807,6.807,0,0,0,1.1-1.868Z" transform="translate(-381.374 -257)" />
                </g>
            </g>
            <g data-id="translation" id="Сгруппировать_11" data-name="Сгруппировать 11" transform="translate(10.422 3.903)">
                <g data-id="translation" id="Сгруппировать_10" data-name="Сгруппировать 10">
                    <path data-id="translation" id="Контур_29" data-name="Контур 29" d="M252.306,91H244.3l1.67,13.4a2,2,0,0,1-.478,1.468l-2.828,3.232h9.643a1.937,1.937,0,0,0,1.934-1.934V92.977A1.974,1.974,0,0,0,252.306,91Zm0,7.136h-.172a8.182,8.182,0,0,1-1.553,2.824c.474.433.98.788,1.484,1.186a.645.645,0,0,1-.806,1.006c-.547-.432-1.061-.793-1.574-1.265-.514.471-.984.833-1.531,1.265a.645.645,0,0,1-.806-1.006c.5-.4.967-.753,1.441-1.186a8.139,8.139,0,0,1-1.51-2.824h-.172a.645.645,0,1,1,0-1.29h1.934V96.2a.645.645,0,1,1,1.29,0v.645h1.977a.645.645,0,0,1,0,1.29Z" transform="translate(-242.663 -91)" />
                </g>
            </g>
            <g data-id="translation" id="Сгруппировать_13" data-name="Сгруппировать 13">
                <g data-id="translation" id="Сгруппировать_12" data-name="Сгруппировать 12">
                    <path data-id="translation" id="Контур_30" data-name="Контур 30" d="M10.5,1.694A1.937,1.937,0,0,0,8.576,0H1.934A1.937,1.937,0,0,0,0,1.934V16.206A1.937,1.937,0,0,0,1.934,18.14H12.1c.188-.215.346-.35.352-.63C12.457,17.44,10.5,1.764,10.5,1.694ZM7.907,12.325a.645.645,0,0,1-.759-.506L6.736,9.758H4.913L4.5,11.819a.645.645,0,1,1-1.264-.253l1.29-6.448A.645.645,0,0,1,5.158,4.6H6.491a.645.645,0,0,1,.632.518l1.29,6.448A.645.645,0,0,1,7.907,12.325Z" />
                </g>
            </g>
            <g data-id="translation" id="Сгруппировать_15" data-name="Сгруппировать 15" transform="translate(7.53 19.421)">
                <g data-id="translation" id="Сгруппировать_14" data-name="Сгруппировать 14">
                    <path data-id="translation" id="Контур_31" data-name="Контур 31" d="M175.261,452l.111.885a1.95,1.95,0,0,0,1.115,1.5L178.651,452Z" transform="translate(-175.261 -452)" />
                </g>
            </g>
        </g>
    </svg>
)

export const addWord = (
    <svg data-id="addWord" id="ic_translate" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <rect data-id="addWord" id="background_jobs" width="24" height="24" fill="none"/>
        <path data-id="addWord" id="plus_1_" data-name="plus (1)" d="M20.036,9.036H13.357a.393.393,0,0,1-.393-.393V1.964a1.964,1.964,0,0,0-3.929,0V8.643a.393.393,0,0,1-.393.393H1.964a1.964,1.964,0,0,0,0,3.929H8.643a.393.393,0,0,1,.393.393v6.679a1.964,1.964,0,1,0,3.929,0V13.357a.393.393,0,0,1,.393-.393h6.679a1.964,1.964,0,1,0,0-3.929Zm0,0" transform="translate(1 1)" />
    </svg>
)