import React from "react";
import logo from "./Image/im_logo.svg";
import * as firebase from "firebase";
import {Cookies} from "./cookies";

// window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
//     "recaptcha-container", {
//         size: "invisible",
//         callback: function(response) {
//             console.log('recaptcha passed');
//             // submitPhoneNumberAuth();
//         }
//     }
// );

export class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            number: "",
            code: ""
        }

        this.handleChangeTel = this.handleChangeTel.bind(this);
        this.handleChangeCode = this.handleChangeCode.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitCode = this.handleSubmitCode.bind(this);
    }

    componentDidMount() {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
            "recaptcha-container", {
                size: "invisible",
                callback: function (response) {
                    console.log('recaptcha passed');
                    // submitPhoneNumberAuth();
                }
            }
        );
    }

    handleChangeTel(e) {
        this.setState({
            number: e.target.value,
        })
    }

    handleChangeCode(e) {
        this.setState({
            code: e.target.value,
        })
    }

    handleSubmit(e) {
        var appVerifier = window.recaptchaVerifier;

        firebase
            .auth()
            .signInWithPhoneNumber(this.state.number, appVerifier)
            .then(function (confirmationResult) {
                window.confirmationResult = confirmationResult;
            })
            .catch(function (error) {
                console.log(error);
                // warring.style.display = "block";
                // warring.textContent = error.message;
            });

        const logTel = document.getElementsByClassName("login")[0];
        const logCode = document.getElementsByClassName("loginCode")[0];

        logTel.style.display = "none";
        logCode.style.display = "flex";


        e.preventDefault()
    }

    async handleSubmitCode(e) {
        const {logIn} = {...this.props};
        window.confirmationResult
            .confirm(this.state.code)
            .then(function (result) {
                var user = result.user;
                console.log("SUBMIT USER:", user);
                console.log(window.confirmationResult);
                Cookies.setCookie("login", true);
                logIn(true);
            })
            .catch(function (error) {
                console.log(error);
            });

        e.preventDefault();
    }


    render() {
        return (
            <>
                <div id="recaptcha-container"/>
                <div className="login">
                    <div className="loginLogo">
                        <img src={logo} alt=""/>
                    </div>
                    <div className="loginForm">
                        <form onSubmit={this.handleSubmit}>
                            <p>Enter your phone number including country area code then press 'Request Code'</p>
                            <br/>
                            <label htmlFor="tel">CELL PHONE</label>
                            <br/>
                            <input type="tel" id="tel" name="tel" onChange={this.handleChangeTel}/>
                            <br/>
                            <input type="submit" value="SIGN IN WITH PHONE"/>
                        </form>
                    </div>
                </div>

                <div className="loginCode">
                    <div className="loginLogo">
                        <img src={logo} alt=""/>
                    </div>
                    <div className="loginForm">
                        <form onSubmit={this.handleSubmitCode}>
                            <p>Enter your phone number including country area code then press 'Request Code'</p>
                            <br/>
                            <label htmlFor="num">sms-code</label>
                            <br/>
                            <input type="tel" id="tel" name="tel" onChange={this.handleChangeCode}/>
                            <br/>
                            <input type="submit" value="SIGN IN WITH PHONE"/>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}
